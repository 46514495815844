import React from 'react';
import WhatWeDo from './WhatWeDo';

function Home(props) {
    return (
        <>
            <WhatWeDo />
        </>
    );
}

export default Home;