import React from 'react';
import Navigation from './Navigation';

function Header(props) {
    return (
        <>
            <Navigation />
        </>
    );
}

export default Header;